@import'../../Variables.scss';

.section-block{
    height: 270px;
    width: 270px;
    background: rgba(255, 255, 255, 0.10);
    box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.15);
    transition: 0.5s;
    backdrop-filter: blur(10px);
    padding: 28px;
    &:hover{
        background: rgba(255, 255, 255, 0.30);
        transition: 0.5s;
		img{
			transition: 0.5s;
			opacity: 1;
			cursor: pointer;
		}
    }
	img{
		margin: 10px 20px 20px 20px;
		width: calc(100% - 40px);
		height: calc(100% - 40px);
		object-fit: cover;
		object-position: 100% 0;
		transition: 0.5s;
		opacity: 0.3;
	}
}

.section-icon {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 16px;
    padding-bottom: 12px;
    border-bottom: 1px solid $qualitas-dark-grey;
    align-items: center;
    justify-content: center;
    height: 75px;
    p{
        margin: 0;
        font-family: $qualitas-font-title;
        font-size: 20px;
        line-height: 20px;
    }
}

.section-options-container{
    margin-top: 15px;
	p{
		margin: 5px 0px;
		font-weight: bold;

	}
}

.ant-popover{
    width: 290px;
    box-shadow: 0px 4px 20px 20px rgba(0, 0, 0, 0.08);
}

.section-icon-circle{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    color: $qualitas-black;
    border-radius: 50px;
}


.home-qf{
	min-height: calc(100vh - 40px);
    overflow: hidden;
    max-width: 100vw;
    background-image: url("https://qualitasfunds.blob.core.windows.net/assets/image-gallery/homepage-line.jpg");
    background-size: cover;
    background-position: bottom;
}

.BlueContainer{
	background-color: $qualitas-light-grey;
	color: $qualitas-dark-grey;
	font-family: $qualitas-font-title;
	font-size: 40px;
	padding: 50px 100px;
	line-height: 1em;
	font-weight: normal;
}

a {
	color: white;
	text-decoration: none;
}

.itemMenu{
	box-sizing: border-box;
	color: $qualitas-black;
	font-family: $qualitas-font-text;
	font-weight: 600;
	cursor: pointer;
	margin-bottom: 5px;
	padding-bottom: 5px;
	&:hover{
		border-bottom: 1px solid $qualitas-grey;
	}
}

.BeigeContainer{
	background-color: $qualitas-dark-grey;
	color: $qualitas-light-grey;
	font-family: $qualitas-font-title;
	font-size: 40px;
	padding: 50px 80px;
	line-height: 1em;
}

.opacityBeige{
	background-color:  $qualitas-green-dark;
	color: $qualitas-black;
	text-align: center;
	padding: 15px;
	cursor: pointer;
	font-family: $qualitas-font-text;
	font-weight: 600;
}

.opacityBeige_{
	background-color: #e3e2dd;
	color: #636364;
	font-family: Arial, Helvetica, sans-serif;
	text-align: center;
	padding: 15px 5px;
	cursor: pointer;
}

.opacityBeige:hover{
	background-color: $qualitas-green;
}

.opacityBlue:hover{
	background-color: $qualitas-green;
	cursor: pointer;
	color: white;
}

.image{
	width: 100%;
	height: 200px;
	object-fit: cover;
	object-position: 100% 0;
}

.textCenter{
  	text-align: center;
}

.first-section {
  	margin-bottom: 40px;
}

.qf-navbar{
	background-color: transparent;
	box-sizing: border-box;
    height: 80px;
	width: 100%;
    display: flex;
    align-items: center;
	justify-content: space-between;
    padding: 0px 50px;
    gap: 50px;
}

.qf-navbar-user{
	display: flex;
	align-items: center;
	gap: 25px;
	button{
		border: none;
		background-color: transparent;
		display: flex;
		align-items: center;
		gap: 15px
	}
	img{
		max-height: 50px;
	}
}

.support-content{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	height: calc(100vh - 80px - 40px);
	padding-bottom: 90px;
}

.section-btn, .powerbi-btn{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding:  0px 0px 12px 0px;
	border: none;
	background-color: transparent;
	color: $qualitas-black;
	text-decoration: none;
	font-weight: 600;
	font-size: 14px;
	&:hover{
		font-weight: bolder;
		color: $qualitas-black;
		cursor: pointer;
	}
	p{
		margin: 0;
	}
}

.title{
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 1400px;
	height: 100px;
	p{
        padding: 25px;
        font-family: $qualitas-font-text;
        font-weight: 300;
        font-size: 38px;
		margin: 0;
        line-height: 40px;
        strong{
            font-weight: 400;
        }
    }
}

.sections-grid{
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 25px;
	max-width: 1500px;
	gap: 25px;
}
@media (max-width: 1300px) {
	.title{
		width: 715px;
		p{
			font-size: 34px;
		}
	}
}

@media (max-width: 760px) {
    .sections-grid{
		display: grid;
        grid-template-columns: 280px;
    }
	.title{
		width: 370px;
	}
	.qf-navbar{
		padding: 0px 20px;
	}	
}