.document-management-filters {
    gap: 20px;
    padding: 8px 0;
    background-color: rgb(238, 237, 237);

    button{
        font-size: 18px;
        padding: 0px;
        justify-content: center;
        align-items: center;
        display: flex;
        p{
            font-size: 14px;
            margin: 0;
        }
    }

    > .ant-col {
        min-width: 10%;
    }

    .ant-picker-range {
        margin-right: -80px;
    }
}

.search-nav-bar {
	background-color: rgb(238, 237, 237);
	width: 100%;
	min-height: 60px;
    z-index: 4;
    flex-direction: column;
    
    &.with-filters {
        border-bottom: 1px solid #485f73;
        border-radius: 0;
    }

    .search-input-wrapper {
        z-index: 4;
    }
}

.options-search {
	display: flex;
	flex-direction: row;
    align-items: center;
    text-align: left;
	gap: 10px;
    padding: 10px ;
    cursor: pointer;
    border: none;
    background-color: white;
    width: 100%;
}

.options-search-folder {
    font-size: 12px;
	color: rgb(151, 151, 151);
	width: 400px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.options-search-row{
    border-bottom: 1px solid rgb(231, 231, 231);
    width: 100%;

    :hover{
        background-color: rgb(241, 241, 241);
    }
}

.options-search-row:hover .view-doc-search{
    display: block;
}


.options-search-tooltip{
    font-size: 12px;
    background-color: rgb(84, 84, 84);
}

.options-search-file{
    max-width: 400px;
    margin-bottom: 5px;
    a{
        color: black;
    }
}

.options-search-list{
    width: 500px;
    position: absolute;
    top: 134px;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    box-shadow: 10px 10px 25px -4px rgb(141, 141, 141);
    z-index: 10;
    min-height: 40px;
    max-height: calc(100vh - (230px));
    overflow-y: scroll;
    overflow-x: hidden;

    &.with-filters {
        top: 120px;
    }
}

.background-docs{
    background-color: rgba(77, 77, 77, 0.5);
    height: 100%;   
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3
}

.enter-text-p{
    width: 100%;
    text-align: center;
    padding-top: 12px;
    font-size: 15px;
    color: rgb(168, 168, 168);
    font-style: italic;
}

.search-criteria{
    width: 100%;
    text-align: center;
    padding-top: 8px;
    font-size: 12px;
    color: rgb(92, 92, 92);
    font-style: italic;
}

.hideOverflow{
    overflow: hidden;
}

.view-doc-search{
    color: rgb(119, 119, 119);
    display:none;
}

.selector-col{
	width: 100%;

    .ant-select {
        width: 100%;
        max-width: 400px;
    }
}