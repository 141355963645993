@import "../../../Variables.scss";

.layout-documents {
  width: 99vw;
  display: flex;
  min-height: calc(100vh - 120px);
  h2 {
	font-family: $qualitas-font-title;
	font-size: 18px;
  }
  .documents-sidebar {
    width: 250px;
    height: 100%;
    background-color: $qualitas-light-grey;
    min-height: calc(100vh - 120px);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    z-index: 8;
    .funds-list {
      padding: 10px 0px 20px 0px;
      box-sizing: border-box;
      .funds-list-title {
        font-family: $qualitas-font-text;
        font-size: 18px;
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        border-bottom: 1px solid $qualitas-dark-grey;
        margin: 0px;
      }
      display: flex;
      flex-direction: column;
      gap: 18px;
      button {
        border: none;
        text-align: start;
        color: $qualitas-dark-grey;
        background-color: transparent;
        &:hover {
          cursor: pointer;
        }
        p {
          margin: 0px;
        }
      }
    }

    h3 {
      padding-left: 20px;
      font-family: $qualitas-font-title;
      font-size: 17px;
    }
    .selectedOption {
      font-weight: bold;
    }
    .documents-btns-container {
      display: flex;
      .documents-by-btn {
        width: 125px;
        border: none;
        border-bottom: 1px solid $qualitas-grey;
        height: 40px;
        cursor: pointer;
      }
      .selected-btn {
        background-color: $qualitas-grey;
        font-weight: 600;
      }
    }
    .skeleton-container {
      padding: 0px 20px;
    }
  }
  .documents-container {
    width: calc(100vw - 110px - 250px);
    overflow-y: auto;
    .ant-tree-list-holder-inner {
      * {
        font-family: $qualitas-font-text;
        font-size: 13px;
      }
    }
  }
}

.generate-documents-container {
	
  margin-bottom: 30px;
  padding-left: 30px;
  .generate-documents-table-section{
    width: 45%;
  }
  h4{
    font-family: $qualitas-font-title;
    font-size: 16px;
    margin: 0px 0px 10px 0px;
  }

  .investors-table {
    font-family: $qualitas-font-text;
    width: 100%;
    border-collapse: collapse;
    tr {
      &:hover {
        background-color: #f0f0f07e;
        .grey-background {
          background-color: #cacacab0;
        }
      }
      border-bottom: 1px solid $qualitas-grey;
    }
    th {
      text-transform: uppercase;
      text-align: center;
      letter-spacing: 0.5px;
      color: $qualitas-dark-grey;
      font-size: 11px;
      height: 35px;
    }
    td {
      text-align: start;
      font-size: 13px;
      height: 40px;
      span {
        color: var(--dynamic-color);
      }
      a {
        color: $qualitas-black;
        cursor: pointer;
        font-weight: bold;
      }
    }
    .currency-tag {
      span {
        background-color: $qualitas-light-grey;
        padding: 8px;
        color: $qualitas-black;
        border-radius: 5px;
        font-size: 13px;
        font-weight: bold;
      }
    }
    .investment-name {
      text-align: start;
      padding-left: 50px;
      padding-right: 10px;
      span {
        color: red;
      }
    }
    .program-name {
      text-align: start;
      font-weight: bold;
      height: 35px;
      font-size: 14px;
      font-family: $qualitas-font-title;
    }
    .report-btn {
      font-size: 13px;
    }
    .grey-background {
      background-color: $qualitas-light-grey;
      font-weight: bold;
    }
    thead > tr {
      border-bottom: 1px solid black;
      &:hover {
        background-color: transparent;
        .grey-background {
          background-color: $qualitas-light-grey;
        }
      }
    }
  }
  .generate-documents-table{
    display: flex;
    gap: 50px;
    width: calc(100vw - 250px - 60px - 90px);

    .generate-documents-form{
      border-left: 1px solid $qualitas-grey;
      padding-left: 50px; 
      .investor-document-form{
        display: flex;
        flex-direction: column;
        gap: 30px;
        h3{
          font-size: 15px;
          margin: 0;
          font-family: $qualitas-font-title;
        }
        p:first-of-type{
            font-weight: bold;
        }
        .ant-select{
            width: 300px;
        }
        .generate-item{
          height: 35px;
          p{
            margin: 0;
            min-width: 100px;
            font-family: $qualitas-font-text;
            font-weight: normal;
            font-size: 13px;
          }
          display: flex;
          align-items: center;
          gap: 15px;
          &:last-of-type{
            border-bottom: 1px solid $qualitas-grey;
            padding-bottom: 40px;
          }
          &:first-of-type{
            margin-top: 20px;
          }
        }
        .open-document-button{
          align-self: center;
          font-size: 13px;
          width: 170px;
          border-radius: 0px;
          &:hover{
            background-color: var(--dynamic-secondary-color);
            color: var(--dynamic-text-color);
            border-color: $qualitas-grey;
          }
          &:disabled:hover{
            background-color: $qualitas-light-grey;
            color: $qualitas-grey;
          }
        }
      }      
    }
  }
  .ant-select-selector, .ant-picker{
    width: 300px ;
    font-size: 13px;
    border-radius: 0px;
    .ant-picker-input > input{
      font-size: 13px;
    }
  }
  .ant-select-item-option-content{
    font-size: 13px;
  }
  input[type="checkbox"] {
    width: 15px;
    height: 15px;
    accent-color: var(--dynamic-color)
    }
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner{
  background-color: var(--dynamic-color);
}
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{
  border: 1px solid var(--dynamic-color)-dark;
}
.ant-picker-today-btn{
  color: $qualitas-black !important;
}
.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector,
.ant-select-focused .ant-select-selector,
.ant-picker:hover, .ant-picker-focused
{
  border-color: var(--dynamic-color) !important;
}
.ant-select-open{
  .ant-select-selector{
    border-color: var(--dynamic-color) !important;
  }
}

.collapse-funds {
  border-top: 1px solid $qualitas-grey;
  border-radius: 0px;
  .ant-collapse-header {
    height: 50px;
    padding: 0px 20px !important;
    display: flex;
    justify-content: space-between;
    align-items: center !important;
    border-bottom: 1px solid $qualitas-grey; 
    border-radius: 0px !important;
    //margin: 10px 0px;
    background-color: transparent;
    span {
      font-size: 13px;
      font-family: $qualitas-font-text;
    }
  }
  .ant-collapse-content-box {
    padding: 0px 0px 0px 20px !important;
  }
}
