@import '../node_modules/@qualitas/auth/dist/Auth.scss';
@import '../node_modules/@qualitas/reporting/dist/AnalysisAndReporting.scss';
@import './Variables.scss';

html, body, #app {
	min-height: 100vh;
}

.header-divider {
	margin-top: 0;
}

.home, .cashflows, .document-generator {
    min-height: calc(100vh - 80px - 40px - 10px);
}



.home {
	background-image: url(https://qualitasfunds.blob.core.windows.net/images/fondosLogin/17.jpg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
	color: #fff;
    font-family: Mukta,Arial;
    font-size: 50px;
    padding-top: 80px;
    position: relative;
    text-align: center;
}

.footer {
	background-color: #485f73;
    color: #fff;
    font-family: Mukta,sans-serif;
    padding: 10px;
    text-align: center;
	height: 4vh;
}

.qf-homepage {
	min-height: calc(100vh - 80px - 40px);
    overflow: hidden;
    max-width: 100vw;
    background-image: url("https://qualitasfunds.blob.core.windows.net/assets/image-gallery/reporting/homepage-img.jpg");
    background-size: cover;
    background-position: bottom;
}

.language-selector {
    display: flex;
    .selectedLanguage {
      color: $qualitas-white;
    }
    button {
      background-color: transparent;
      color: $qualitas-dark-grey;
      width: 50px;
      border: none;
      &:first-of-type {
        border-right: 1px solid $qualitas-dark-grey;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }

  .homePage-navbar-extracontent{
    display: flex;
    .language-selector {
        .selectedLanguage {
            color: $qualitas-black;
          }
        button{
            display: flex;
            justify-content: center;
            color: $qualitas-grey;
        }
    }
  }
  
.homePage-navbar{
	background-color: transparent;
	box-sizing: border-box;
    height: 80px;
	width: 100%;
    display: flex;
    align-items: center;
	justify-content: space-between;
    padding: 0px 50px;
    gap: 50px;
	img{
		max-width: 150px;
		max-height: 80px;
	}
}

.homePage-navbar-user{
	display: flex;
	align-items: center;
	gap: 25px;
	button{
		border: none;
		background-color: transparent;
		display: flex;
		align-items: center;
		gap: 15px
	}
}

.cashflows-reporting{
  padding-left: 25px;
}

.layout-sider-documents{
  background-color: $qualitas-light-grey !important;
}